import {I18n} from "i18n-js";

// Setup I18n object with sane defaults.
const i18n = new I18n();
i18n.enableFallback = true;
i18n.defaultLocale = "en";
i18n.locale = document.body.dataset.locale;

const translationKeyPrefix = "javascript"

// Override translate method to automatically add prefix for easier key resolution.
const translateWithoutPrefix = i18n.translate.bind(i18n);
const translateWithPrefix = (scope, options) => {
  if (typeof scope === "string")
    scope = translationKeyPrefix + "." + scope;
  else if (scope instanceof Array)
    scope = scope.unshift(translationKeyPrefix);

  return translateWithoutPrefix(scope, options);
}


// Replace instance's methods with modified methods.
const translate = (scope, options, prefix = true) => (prefix ? translateWithPrefix(scope, options) : translateWithoutPrefix(scope, options))
i18n.translate = translate;
i18n.t = i18n.translate;


// Assign I18n object as global variable, accessible from anywhere.
window.I18n = i18n;
